import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/Blog/PostList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';
import { GlobalCta } from '../components/Acf/GlobalCta'

const Category = (props) => {
  const { data, pageContext, location } = props
  const { postsOnPage, categoryFilter, categories, thisCategory, site, wordpressWpSettings, yoast = [], siteSettings } = data
  const { wordpressUrl } = wordpressWpSettings
  const { showAuthor } = siteSettings.options
  const { edges: posts, totalCount } = postsOnPage
  const { title: siteTitle, date_format } = wordpressWpSettings
  const { name: category, slug, pathPrefix } = pageContext
  const title = `${category} Category`
  const featuredImage = thisCategory.nodes[0] && thisCategory.nodes[0].featured_media ? thisCategory.nodes[0].featured_media : null

  return (
    <Layout className={`page-wrap`} wordpressUrl={wordpressUrl}>
      <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} yoast={yoast}
        location={location}
      />
      <PageHeader headerTitle={title} location={location} headerBackgroundImage={featuredImage} />
      <PostList
        posts={posts}
        title={title}
        pageContext={pageContext}
        categoryFilter={categoryFilter}
        siteMetadata={wordpressWpSettings}
        pathPrefix={pathPrefix}
        showAuthor={showAuthor}
        dateFormat={date_format}
      />
      <GlobalCta />
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
      }
    },
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "dummy-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    thisCategory: allWordpressCategory(filter: {slug: {eq: $slug}}) {
      nodes {
        name
        slug
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ... GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
